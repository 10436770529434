// @flow

import createApiModule from "../create-api-module";
import { notifyApiError } from "./notifications";

// DEPRECATED?

const { constants: _constants, reducer, actions } = createApiModule(
    "getFormsWithVersion",
    ({ templateId, versionId }) =>
        `/forms/${templateId}?version-id=${versionId}`,

    {
        method: "GET",
        errorHandler: notifyApiError(
            "Formulieren voor inspectie ophalen mislukt.",
        ),
    },
);

export const refetch = actions.refetch;
export const clear = actions.clear;
export const load = actions.load;
export const constants = _constants;

export default reducer;
